import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import modals from '@src/utils/constants/modals';
import Preloader from '@comp/Preloader';

const LogDrawerModal = lazy(() => import('../LogDrawerModal'));
const LogFoodstuffModal = lazy(() => import('../LogFoodstuffModal'));
const LogDrawerRecipeModal = lazy(() => import('../LogDrawerRecipeModal'));
const LogFoodstuffModalRecipe = lazy(() =>
  import('../LogFoodstuffModalRecipe')
);
const MergeConfirmationModal = lazy(() => import('../MergeConfirmationModal'));
const RecipeCategoriesDrawerModal = lazy(() =>
  import('../RecipeCategoriesDrawerModal')
);
const LogRecipeModal = lazy(() => import('../LogFoodModal/LogRecipeModal'));
const LogConfirmationModal = lazy(() => import('../LogConfirmationModal'));
const ConfirmationModal = lazy(() => import('../ConfirmationModal'));
const EditIngredientGroupModal = lazy(() =>
  import('../EditIngredientGroupModal')
);
const EditInstructionHeaderModal = lazy(() =>
  import('../EditInstructionHeaderModal')
);
const QuickLogModal = lazy(() => import('../QuickLogModal'));
const NotesModal = lazy(() => import('../NotesModal'));
const LogExerciseModal = lazy(() => import('../LogExerciseModal'));
const LogWeightModal = lazy(() => import('../LogWeightModal'));
const LogWaistModal = lazy(() => import('../LogWaistModal'));
const AlertModal = lazy(() => import('../AlertModal'));
const ShareRecipeModal = lazy(() => import('../ShareRecipeModal'));
const HealthDataConsentModal = lazy(() => import('../HealthDataConsentModal'));
const SwitchMealModal = lazy(() => import('../SwitchMealModal'));
const RecipeFiltersDrawerModal = lazy(() =>
  import('../RecipeFiltersDrawerModal')
);

const ModalRoot = ({ activeModals }) => {
  if (!activeModals) {
    return null;
  }
  // Render the modals with isOpen=false instead of rendering null in order to
  // let the visibility be handled by ModalBase and react-modal.
  return (
    <div>
      <Suspense fallback={<Preloader />}>
        <LogDrawerModal {...getProps(modals.LOG_DRAWER_MODAL_ID)} />
        <LogDrawerRecipeModal
          {...getProps(modals.LOG_DRAWER_RECIPE_MODAL_ID)}
        />
        <LogFoodstuffModal {...getProps(modals.LOG_FOODSTUFF_MODAL_ID)} />
        <RecipeCategoriesDrawerModal
          {...getProps(modals.RECIPE_CATEGORIES_MODAL_ID)}
        />
        <LogFoodstuffModalRecipe
          {...getProps(modals.ADD_RECIPE_FOODSTUFF_MODAL_ID)}
        />
        <LogRecipeModal {...getProps(modals.LOG_RECIPE_MODAL_ID)} />
        <LogExerciseModal {...getProps(modals.LOG_EXERCISE_MODAL_ID)} />
        <LogWeightModal {...getProps(modals.LOG_WEIGHT_MODAL)} />
        <LogWaistModal {...getProps(modals.LOG_WAIST_MODAL)} />
        <QuickLogModal {...getProps(modals.QUICK_LOG_MODAL)} />
        <NotesModal {...getProps(modals.NOTES_MODAL)} />
        <HealthDataConsentModal
          {...getProps(modals.HEALTH_DATA_CONSENT_MODAL)}
        />
        <SwitchMealModal {...getProps(modals.SWITCH_MEAL_MODAL)} />
        <RecipeFiltersDrawerModal
          {...getProps(modals.RECIPE_FILTERS_DRAWER_MODAL)}
        />
        <ShareRecipeModal {...getProps(modals.SHARE_RECIPE_MODAL)} />
        <AlertModal {...getProps(modals.ALERT_MODAL)} />
        <MergeConfirmationModal
          {...getProps(modals.MERGE_CONFIRMATION_MODAL)}
        />
        <LogConfirmationModal {...getProps(modals.LOG_CONFIRMATION_MODAL)} />
        <ConfirmationModal {...getProps(modals.CONFIRMATION_MODAL)} />
        <EditIngredientGroupModal
          {...getProps(modals.EDIT_INGREDIENT_GROUP_MODAL)}
        />
        <EditInstructionHeaderModal
          {...getProps(modals.EDIT_INSTRUCTION_HEADLINE_MODAL)}
        />
      </Suspense>
    </div>
  );

  function getProps(id) {
    return activeModals.reduce(
      (props, modal) =>
        modal.id === id ? { ...modal.modalProps, isOpen: true } : props,
      { isOpen: false }
    );
  }
};

ModalRoot.propTypes = {
  activeModals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      modalProps: PropTypes.shape({})
    })
  )
};

export default ModalRoot;
